import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import axios from "axios";
import { UserbackProvider } from "@userback/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Routes
import Home from "./features/counter";
import Notification from "./features/notification";
import Crud from "./features/crud";
import CrudCreate from "./features/crud/Create";
import Company from "./features/company";
import CompanyCreate from "./features/company/Create";
import CompanyView from "./features/company/View";
import CompanyEdit from "./features/company/Edit";
import EmployeeEdit from "./features/employee/Edit";

import CompanyDraftEdit from "./features/company/DraftEdit";
import EmployeeDraftEdit from "./features/employee/DraftEdit";

import Ticket from "./features/ticket";
import TicketCreate from "./features/ticket/Create";
import TicketShow from "./features/ticket/Show";
import Calculator from "./features/calculator";
import CalculatorShow1 from "./features/calculator/Show1";
import CalculatorShow2 from "./features/calculator/Show2";
import CalculatorShow3 from "./features/calculator/Show3";
import CalculatorShow4 from "./features/calculator/Show4";
import CalculatorShow5 from "./features/calculator/Show5";
import CalculatorEdit from "./features/calculator/Edit";

import Vendor from "./features/vendor";
import VendorCreate from "./features/vendor/Create";
import VendorShow from "./features/vendor/Show";
import VendorEdit from "./features/vendor/Edit";

import Supplier from "./features/supplier";
import SupplierCreate from "./features/supplier/Create";
import SupplierShow from "./features/supplier/Show";
import SupplierEdit from "./features/supplier/Edit";

import RecruitJobApplication from "./features/recruitJobApplication";
import RecruitJobApplicationCreate from "./features/recruitJobApplication/Create";
import RecruitJobApplicationEdit from "./features/recruitJobApplication/Edit";
import Office from "./features/office";
import OfficeCreate from "./features/office/Create";
import OfficeEdit from "./features/office/Edit";
import Attendance from "./features/attendance";
import AttendanceCreate from "./features/attendance/Create";
import MobileApp from "./features/attendance/MobileApp";
import LeaveConsumption from "./features/leavesConsumption";
import LeaveConsumptionShow from "./features/leavesConsumption/Show";
import MobileNav from "./layout/MobileNav";
import SideNav from "./layout/SideNav";
import SearchHeader from "./layout/SearchHeader";
import Loader from "layout/Loader";
import { ListService } from "features/service";
import ListEmployee from "features/employee/List";
import EmployeeView from "features/employee/View";
import CreateEmployee from "features/employee/Create";
import { ListRole } from "features/role";
import CreateRole from "features/role/Create";
import ViewRole from "features/role/View";
import { ListPermissions } from "features/permissions";
import CreateDepartment from "features/department/Create";
import ViewDepartment from "features/department/View";
import { ListDepartment } from "features/department";

import DepartmentEdit from "./features/department/Edit";

import ListWorkflow from "features/workflow/list";
import Edit from "features/company/Edit";

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;

// Global variables
const grandizerV1Url = process.env.REACT_APP_V1_URL;

function App() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState([]);
  const [navItems, setNavItems] = useState([]);
  const [badgeCountDashboard, setBadgeCountDashboard] = useState(0);
  const [badgeCountNotification, setBadgeCountNotification] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    // Get user detail
    axios
      .get("/me")
      .then(function (response) {
        // handle success
        //console.log(response.data);
        if (response.data.status === "not_logged_in") {
          window.location.href =
            grandizerV1Url + "/login?url=" + window.location.href;
        } else {
          setUser(response.data.data);

          // Set favicon
          const faviconUrl = response.data.data.company_favicon_url;
          if (faviconUrl) {
            document.querySelector("link[rel='shortcut icon']").href =
              faviconUrl;
            document.querySelector("link[rel='apple-touch-icon']").href =
              faviconUrl;
          }

          // Get navigation
          axios
            .get(
              grandizerV1Url + "/v2-api/sidenav"
            )
            .then(function (response) {
              if (response.data.status === "success") {
                setNavItems(response.data.nav_items);
                setIsLoaded(true);

                // Get notification count
                axios
                  .get(grandizerV1Url + "/v2-api/user/notification-count")
                  .then(function (response) {
                    if (response.data.task_count) {
                      setBadgeCountDashboard(response.data.task_count);
                      setBadgeCountNotification(
                        response.data.notification_count
                      );
                    }
                  });
              }
            });
        }
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
      })
      .then(function () {
        //setIsLoaded(true);
      });
  }, []);

  //console.log(location.substring(0, 24));

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <BrowserRouter>
      <div className="min-h-full">
        <ToastContainer theme="colored" />
        <MobileNav
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          user={user}
          navItems={navItems}
          badgeCountDashboard={badgeCountDashboard}
          badgeCountNotification={badgeCountNotification}
        />

        <SideNav
          user={user}
          navItems={navItems}
          grandizerV1Url={grandizerV1Url}
          badgeCountDashboard={badgeCountDashboard}
          badgeCountNotification={badgeCountNotification}
        />

        {/* Main column */}
        <div className="lg:pl-56 flex flex-col">
          {/* Search header */}
          <SearchHeader user={user} setSidebarOpen={setSidebarOpen} />

          <main className="flex-1">
            <Routes>
              <Route exact path="/" element={<Home user={user} />} />
              <Route
                path="/notifications"
                element={<Notification user={user} />}
              />
              <Route path="/crud" element={<Crud user={user} />} />
              <Route path="/crud/create" element={<CrudCreate user={user} />} />
              <Route path="/services" element={<ListService user={user} />} />
              <Route path="/employees" element={<ListEmployee user={user} />} />
              <Route
                path="/employee/view/:employeeId"
                element={<EmployeeView user={user} />}
              />
              <Route path="/companies" element={<Company user={user} />} />
              <Route path="/roles" element={<ListRole user={user} />} />
              <Route path="/role/create" element={<CreateRole user={user} />} />
              <Route path="/role/edit/:id" element={<ViewRole user={user} />} />
              <Route
                path="/permissions"
                element={<ListPermissions user={user} />}
              />

              <Route
                path="/company/view/:companyId"
                element={<CompanyView user={user} />}
              />
              <Route
                path="/company/create"
                element={<CompanyCreate user={user} />}
              />
              <Route
                path="/employees/create"
                element={<CreateEmployee user={user} />}
              />
              <Route
                path="/company/edit/:companyId"
                element={<Edit user={user} />}
              />
              <Route
                path="/employee/edit/:employeeId"
                element={<EmployeeEdit user={user} />}
              />

              <Route
                path="/department/create"
                element={<CreateDepartment user={user} />}
              />
              <Route
                path="/departments/view/:departmentId"
                element={<ViewDepartment user={user} />}
              />
              <Route
                path="/departments"
                element={<ListDepartment user={user} />}
              />
              <Route
                path="/department/edit/:editDepartmentId"
                element={<DepartmentEdit user={user} />}
              />

              <Route
                path="/transaction"
                element={<ListWorkflow user={user} />}
              />

              <Route
                path="/company/draft/edit/:draftId"
                element={<CompanyDraftEdit user={user} />}
              />
              <Route
                path="/employee/draft/edit/:draftEmployeeId"
                element={<EmployeeDraftEdit user={user} />}
              />

              <Route path="/tickets" element={<Ticket user={user} />} />
              <Route
                path="/tickets/create"
                element={<TicketCreate user={user} />}
              />
              <Route
                path="/tickets/:ticketId/create"
                element={<TicketCreate user={user} />}
              />
              <Route
                path="/tickets/:ticketId"
                element={<TicketShow user={user} />}
              />
              <Route path="/calculators" element={<Calculator user={user} />} />
              <Route
                path="/calculators/1"
                element={<CalculatorShow1 user={user} />}
              />
              <Route
                path="/calculators/2"
                element={<CalculatorShow2 user={user} />}
              />
              <Route
                path="/calculators/3"
                element={<CalculatorShow3 user={user} />}
              />
              <Route
                path="/calculators/4"
                element={<CalculatorShow4 user={user} />}
              />
              <Route
                path="/calculators/5"
                element={<CalculatorShow5 user={user} />}
              />
              <Route
                path="/calculators/:calculatorId/edit"
                element={<CalculatorEdit user={user} />}
              />
              <Route path="/vendors" element={<Vendor user={user} />} />
              <Route
                path="/vendors/create"
                element={<VendorCreate user={user} />}
              />
              <Route
                path="/vendor/view/:modelId"
                element={<VendorShow user={user} />}
              />
              <Route
                // path="/vendor/edit/:modelId/edit"
                path="/vendor/edit"
                element={<VendorEdit user={user} />}
              />

              <Route path="/suppliers" element={<Supplier user={user} />} />
              <Route
                path="/suppliers/create"
                element={<SupplierCreate user={user} />}
              />
              <Route
                path="/supplier/view/:modelId"
                element={<SupplierShow user={user} />}
              />
              <Route
                // path="/vendor/edit/:modelId/edit"
                path="/supplier/edit"
                element={<SupplierEdit user={user} />}
              />

              <Route
                path="/recruit-job-applications"
                element={<RecruitJobApplication user={user} />}
              />
              <Route
                path="/recruit-job-applications/create"
                element={<RecruitJobApplicationCreate user={user} />}
              />
              <Route
                path="/recruit-job-applications/:modelId/edit"
                element={<RecruitJobApplicationEdit user={user} />}
              />
              <Route
                path="/recruit-job-applications/:modelId"
                element={<RecruitJobApplication user={user} />}
              />
              <Route path="/offices" element={<Office user={user} />} />
              <Route
                path="/offices/create"
                element={<OfficeCreate user={user} />}
              />
              <Route
                path="/offices/:modelId/edit"
                element={<OfficeEdit user={user} />}
              />
              <Route
                path="/mobile-app-attendance"
                element={<MobileApp user={user} />}
              />
              <Route path="/attendances" element={<Attendance user={user} />} />
              <Route
                path="/attendances/create"
                element={<AttendanceCreate user={user} />}
              />
              <Route
                path="/attendances/:modelId"
                element={<Attendance user={user} />}
              />
              <Route
                path="/leave-consumptions"
                element={<LeaveConsumption user={user} />}
              />
              <Route
                path="/leave-consumptions/:modelId"
                element={<LeaveConsumptionShow user={user} />}
              />
            </Routes>
          </main>
        </div>

        {process.env.NODE_ENV != "development" && (
          <UserbackProvider
            token="32835|60488|JrHbQRTnZd1zaQzt76EjGD1Qn"
            options={{
              email: user.email,
              name: user.name,
              custom_data: {
                account_id: user.id,
              },
              widget_settings: {
                position: "e",
              },
            }}
          ></UserbackProvider>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;
