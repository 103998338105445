import TextField from "components/fields/TextField";
import Numberfield from "components/fields/numberfield";
import FormSection from "components/forms/FormSection";
import FormTabs from "components/forms/FormTabs";
import React, { useState, useEffect } from "react";
import { Form, Field, FormSpy, useForm, useFormState } from "react-final-form";
import GrandizerSingleFileUploadField from "components/fields/GrandizerSingleFileUploadField";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import DatePickerField from "components/fields/DatePickerField";
import GrandizerArrayField from "../../../components/fields/GrandizerArrayField";
import WorkingHoursToggle from "components/fields/WorkingHoursToggle";
import FormPercentage from "components/forms/FormPercentage";
import TextFieldSalery from "components/fields/textfieldsalery";
import TextFieldSaleryTotal from "components/fields/textfieldsalerytotal";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import axios from "axios";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";
import {
  nationalityArabicListing,
  nationalListing,
  countryListing,
} from "constants/employyes/employee";
import { Switch } from "@headlessui/react";
import IsRemoteToggleFIeld from "components/fields/IsRemoteToggleFIeld";
import { useRef } from "react";
import TimezoneSelectField from "components/fields/TimezoneSelectField";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import SponserShipField from "components/fields/sponsershipFIeld";
import CustomToggleField from "components/fields/customToggleField";
import TextFieldEmail from "components/fields/TextFieldEmail";
import { validateAllFields } from "helpers/validationForEmployees";
import ErrorModification from "features/company/components/ErrorModification";

export default function CrudForm(props) {
  const {
    onSubmit,
    initialValues,
    errorMsg,
    initialRequiredField,
    tabSelect,
    setData,
    user,
    isDisableDraft,
    setIsDisableDraft,
    errorHold,
    setErrorHold,
    setCustomError,
    submitted,
    setSubmitted,
    nonRegisterederror,
    isDisableSubmit,
  } = props;

  const [formState, setFormState] = useState();
  const [allRoles, setAllRoles] = useState([]);
  const [lineManager, setLineManager] = useState([]);
  const [companyDpt, setCompanyDpt] = useState([]);
  const [companyDPId, setCompanyDpId] = useState();
  const [departmentData, setDepartmentData] = useState();
  const [selectedValue, setSelectedValue] = useState(null);
  const departmentREf = useRef(null);
  let navigate = useNavigate();

  if (formState) {
    if (formState?.is_remote === true) {
      initialValues["timezone"] = "";
      initialValues["close_payroll_day"] = "";
      initialRequiredField.working_hours["timezone"] = "";
      initialRequiredField.working_hours["close_payroll_day"] = "";
      delete initialRequiredField.working_hours?.Working_at_office;
      delete initialValues?.Working_at_office;
    }
    if (!formState.is_remote || formState?.is_remote === false) {
      initialValues["Working_at_office"] = "";
      initialRequiredField.working_hours["Working_at_office"] = "";
      delete initialValues?.timezone;
      delete initialValues?.close_payroll_day;
      delete initialRequiredField.working_hours?.timezone;
      delete initialRequiredField.working_hours?.close_payroll_day;
    }
  }

  if (formState) {
    if (formState?.nationality?.value === "Saudi") {
      delete initialValues?.hometown_address;
      delete initialRequiredField.personal_information?.hometown_address;
      delete initialValues?.passport_no;
      delete initialRequiredField.bank_passport_information?.passport_no;
      delete initialValues?.passport_expire_date;
      delete initialRequiredField.bank_passport_information
        ?.passport_expire_date;
      delete initialValues?.passport_file;
      delete initialRequiredField.bank_passport_information?.passport_file;
    } else {
      initialValues["hometown_address"] = "";
      initialRequiredField.personal_information["hometown_address"] = "";
      initialValues["passport_no"] = "";
      initialRequiredField.bank_passport_information["passport_no"] = "";
      initialValues["passport_expire_date"] = "";
      initialRequiredField.bank_passport_information["passport_expire_date"] =
        "";
      initialValues["passport_file"] = null;
      initialRequiredField.bank_passport_information["passport_file"] = null;
    }
  }

  useEffect(() => {
    getRolesInfo();
  }, []);

  async function getRolesInfo() {
    await axios
      .get("role/all", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setAllRoles(response.data.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  useEffect(() => {
    getLineManger();
  }, []);

  async function getLineManger() {
    await axios
      .get("create/users", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setLineManager(response.data.data.users);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  useEffect(() => {
    getCompanyDepartment();
  }, []);

  async function getCompanyDepartment() {
    await axios
      .get("crete/companies-with-departments", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setCompanyDpt(response.data.data?.departments);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  useEffect(() => {
    getSpecificCompanyDepartment();
  }, [companyDPId]);

  const [selectedNumber, setSelectedNumber] = useState(1);
  const closePayRoll = Array.from({ length: 27 }, (_, index) => index + 1);

  async function getSpecificCompanyDepartment() {
    await axios
      .get("company/department/" + companyDPId, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        // setDepartmentData(Object?.values(response.data.data?.department));
        const responseData = response.data.data;

        setDepartmentData(responseData);

        // const selectedValueInResponse = responseData.department?.some(item => item.name === selectedValue);

        // // update the dropdown value if necessary
        // if (departmentREf.current && !selectedValueInResponse) {
        //   departmentREf.current.value = null;
        //   setSelectedValue(null);
        // }
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  // const person = {
  //   firstName: 'John',
  //   lastName: 'Doe'
  // };

  // const propertyValues = Object.values(person);

  const roles = allRoles.map((item) => item);
  const lineMg = lineManager.map((item) => item);
  const companyDpOptions = companyDpt.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const removeASuperAdmin = roles.filter((item) => item.name !== "Super Admin");

  const rolesOptions = removeASuperAdmin.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));
  const lineManagerOptions = lineMg.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const departmentOptions = departmentData?.department?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));
  const locationsOptions = departmentData?.location?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const divisionOptions = departmentData?.division?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const officeOptions = departmentData?.office?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  // const withoutEmployee = departmentData?.position?.filter(
  //   (item) => item.name !== "Employee"
  // );
  const positionOptions = departmentData?.position?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  //  const defaultPosition = positionOptions?.filter(item => item.value === "Employee" && item );
  // const objeposition = positionOptions?.find(obj => obj?.value === 'Employee' );

  let objeposition = positionOptions?.find((obj) => obj?.value === "Employee");

  useEffect(() => {
    if (objeposition) {
      setData(objeposition);
    }
  }, [departmentData?.position]);

  //  setCount(objeposition);
  const [sponsorship, setSponsorship] = useState();
  const handleChange = (e, input) => {
    setCompanyDpId(e?.id);
    formState.company_location_id = null;
    formState.department_id = null;
    formState.position_id = "";
    // setSponsorship(formState?.company_id?.value)
    formState.sponsorship = formState?.company_id?.value;
    input.onChange(e);
  };

  useEffect(() => {
    // setSponsorship(formState?.company_id?.value)
    // formState.sponsorship = sponsorship;
  }, [formState?.sponsorship]);

  // defaultPosition(objeposition);

  const [currentTab, setCurrentTab] = useState("personal_information");

  const tabs = [
    {
      name: (
        <span>
          Personal Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "personal_information",
      bgColor: null,
      fields: [
        {
          name: "name",
        },
        {
          name: "name_arabic",
        },
        {
          name: "profile_pic_url",
        },
        {
          name: "gender",
        },
        {
          name: "dob",
        },
        {
          name: "nationality",
        },
        {
          name: "nationality_ar",
        },
        {
          name: "res_country",
        },
        {
          name: "res_country_ar",
        },
        {
          name: "hometown_address",
        },
        {
          name: "local_address",
        },
        {
          name: "marital_status",
        },
        {
          name: "number_of_wife",
        },
        {
          name: "number_of_children",
        },
      ],
    },

    // { name: "Address", key: "address" },
    {
      name: (
        <span>
          Contact Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "contact_information",
      bgColor: null,
      fields: [
        {
          name: "email",
        },
        {
          name: "personal_email",
        },
        {
          name: "cellphone_number",
        },
        {
          name: "alternative_mobile",
        },
        {
          name: "emergency_contact_relationship",
        },
        {
          name: "emergency_contact_name",
        },
        {
          name: "emergency_contact_mobile_number",
        },
      ],
    },

    {
      name: (
        <span>
          Bank & Passport Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "bank_passport_information",
      bgColor: null,
      fields: [
        {
          name: "passport_no",
        },
        {
          name: "passport_expire_date",
        },
        {
          name: "passport_file",
        },
        {
          name: "iban_fileurl",
        },
        {
          name: "bank_accounts",
          props: {
            keyValue: "updateEmployee",
            columns: [
              { key: "bank_name", label: "Bank Name" },
              { key: "iban", label: "Account Number" },
            ],
          },
        },
      ],
    },
    {
      name: (
        <span>
          Company Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "company_information",
      bgColor: null,

      fields: [
        {
          name: "joining_date",
        },
        {
          name: "leaving_date",
        },
        {
          name: "company_id",
        },
        {
          name: "company_location_id",
        },
        {
          name: "position_id",
        },
        {
          name: "department_id",
        },
        {
          name: "division_id",
        },
        {
          name: "job_title",
        },
        {
          name: "job_title_ar",
        },
        {
          name: "line_manager_id",
        },
        {
          name: "system_roles",
        },
        {
          name: "sponsorship",
        },
      ],
    },

    // { name: "Documents", key: "documents" },
    // { name: "Bank Detail", key: "bank_detail" },
    {
      name: (
        <span>
          Iqama & Medical Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "iqama_medical_information",
      bgColor: null,
      fields: [
        {
          name: "national_iqama_id",
        },
        {
          name: "national_iqama_id_expire_date",
        },
        {
          name: "iqama_id_file",
        },
        {
          name: "medical_membership_no",
        },
      ],
    },
    {
      name: (
        <span>
          Salary Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "salary_information",
      bgColor: null,
      fields: [
        {
          name: "basic_salary",
        },
        {
          name: "housing_salary",
        },
        {
          name: "transpiration_salary",
        },
        {
          name: "salary_total",
        },
      ],
    },
    {
      name: (
        <span>
          Working Hours<span className="text-red-500"> * </span>
        </span>
      ),
      key: "working_hours",
      bgColor: null,
      fields: [
        {
          name: "Working_at_office",
        },
        {
          name: "timezone",
        },
        {
          name: "close_payroll_day",
        },
        {
          name: "group_department_toggle",
        },
        {
          name: "sunday_timing",
        },
        {
          name: "monday_timing",
        },
        {
          name: "tuesday_timing",
        },
        {
          name: "wednesday_timing",
        },
        {
          name: "thursday_timing",
        },
        {
          name: "friday_timing",
        },
        {
          name: "saturday_timing",
        },
      ],
    },

    {
      name: (
        <span>
          Job Type<span className="text-red-500"> * </span>
        </span>
      ),
      key: "job_type",
      bgColor: null,
      fields: [
        {
          name: "job_type",
        },
        {
          name: "is_remote",
        },
        {
          name: "contracts",
        },
        {
          name: "contracts_file",
        },
      ],
    },
  ];

  function saveAsDraft(values) {
    setIsDisableDraft(true);

    //  debugger;

    const roleIds = values.roles?.map((item) => parseInt(item.id));

    const businessHours = {
      friday: [
        values.friday_timing
          ? values.friday_timing
          : { open: null, close: null },
      ],
      saturday: [
        values.saturday_timing
          ? values?.saturday_timing
          : { open: null, close: null },
      ],
      sunday: [
        values.sunday_timing
          ? values.sunday_timing
          : { open: null, close: null },
      ],
      monday: [
        values.monday_timing
          ? values.monday_timing
          : { open: null, close: null },
      ],
      tuesday: [
        values.tuesday_timing
          ? values.tuesday_timing
          : { open: null, close: null },
      ],
      wednesday: [
        values.wednesday_timing
          ? values.wednesday_timing
          : { open: null, close: null },
      ],
      thursday: [
        values.thursday_timing
          ? values.thursday_timing
          : { open: null, close: null },
      ],
    };

    const finalDraftData = {
      ...values,
      name: values?.name && values.name,
      profile_pic_url: values.profile_pic_url && values.profile_pic_url.fileUrl,
      profilepic_filename:
        values.profile_pic_url && values.profile_pic_url.fileName,
      name_arabic: values.name_arabic && values.name_arabic,
      dob: values.dob && moment(values.dob).format("YYYY-MM-DD"),
      gender: values?.gender && values.gender,
      nationality: values.nationality?.value && values.nationality?.value,
      nationality_ar:
        values.nationality_ar?.value && values.nationality_ar?.value,
      res_country: values.res_country?.value && values.res_country?.value,
      res_country_ar:
        values.res_country_ar?.value && values.res_country_ar?.value,
      hometown_address: values.hometown_address && values.hometown_address,
      local_address: values?.local_address && values.local_address,
      marital_status: values?.marital_status && values.marital_status,
      number_of_wife:
        values.marital_status === "Married" && values?.number_of_wife,
      number_of_children:
        values.marital_status === "Married" ||
        values.marital_status === "Divorced"
          ? values?.number_of_children
          : "",

      email: values?.email && values?.email,
      personal_email: values?.personal_email && values?.personal_email,
      cellphone_number: values.cellphone_number && values.cellphone_number,
      alternative_mobile:
        values?.alternative_mobile && values.alternative_mobile,
      emergency_contact_relationship:
        values?.emergency_contact_relationship &&
        values.emergency_contact_relationship,
      emergency_contact_name:
        values.emergency_contact_name && values.emergency_contact_name,
      emergency_contact_mobile_number:
        values.emergency_contact_mobile_number &&
        values.emergency_contact_mobile_number,

      passport_no: values.passport_no && values.passport_no,
      passport_expire_date:
        values?.passport_expire_date &&
        moment(values?.passport_expire_date).format("YYYY-MM-DD h:mm:ss"),
      passport_file: values.passport_file && values.passport_file.fileUrl,
      passport_filename: values.passport_file && values.passport_file.fileName,
      iban_file: values.iban_file && values.iban_file.fileUrl,
      iban_filename: values.iban_file && values.iban_file.fileName,
      bank_accounts:
        values.bank_accounts && JSON.stringify(values.bank_accounts),

      joining_date:
        values.joining_date &&
        moment(values.joining_date).format("YYYY-MM-DD h:mm:ss"),
      leaving_date:
        values.leaving_date &&
        moment(values.leaving_date).format("YYYY-MM-DD h:mm:ss"),
      position_id: values.position_id?.id && values.position_id?.id,
      roles: values.roles && JSON.stringify(roleIds),
      company_id: values.company_id && values.company_id?.id,
      company_location_id:
        values.company_location_id && values.company_location_id?.id,
      department_id: values.department_id && values.department_id?.id,
      division_id: values.division_id && values?.division_id?.id,
      job_title: values.job_title && values.job_title,
      job_title_ar: values.job_title_ar && values.job_title_ar,
      line_manager_id: values.line_manager_id && values.line_manager_id?.id,

      national_iqama_id: values.national_iqama_id && values.national_iqama_id,
      national_iqama_id_expire_date:
        values.national_iqama_id_expire_date &&
        moment(values.iqama_id_expire_date).format("YYYY-MM-DD h:mm:ss"),
      national_iqama_id_fileurl:
        values.iqama_id_file && values.iqama_id_file.fileUrl,
      national_iqama_id_filename:
        values.iqama_id_file && values.iqama_id_file.fileName,
      medical_membership_no:
        values.medical_membership_no && values.medical_membership_no,

      basic_salary: values.basic_salary && values.basic_salary,
      housing_salary: values.housing_salary && values.housing_salary,
      transpiration_salary:
        values.transpiration_salary && values.transpiration_salary,
      business_hours: JSON.stringify(businessHours),
      timezone: values.timezone && values.timezone,
      close_payroll_day:
        values.close_payroll_day && parseInt(values.close_payroll_day),
      group_department_toggle:
        values.group_department_toggle && values.group_department_toggle,

      job_type: values.job_type && values.job_type,
      is_remote:
        values.is_remote === true
          ? true
          : values.is_remote === false
          ? false
          : false,

      contracts: values.contracts && values.contracts,
      contracts_file: values.contracts_file && values.contracts_file.fileUrl,
      contracts_fileName:
        values.contracts_file && values.contracts_file?.fileName,
      working_days: 1,

      // national_address_file: national_address_file,
      // cr_number_file: cr_number_file,
      // gosi_file: gosi_file,
      // vat_file: vat_file,
      // coc_file: coc_file,
      // gazt_file: gazt_file,
      // bank_account_file: bank_account_file,
      // municipality_file: municipality_file,
      // logo: logo,
      // logo_dark: logo_dark,
      // favicon: favicon,
    };
    //  debugger

    const formData = new FormData();

    formData.append("user-id", user.id);
    formData.append("model", "employee");
    formData.append("data", JSON.stringify(finalDraftData));
    axios
      .post("draft/store", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        // setUsersData(response.data.data.users);
        toast.success("Draft save!");
        setTimeout(function () {
          setIsDisableDraft(false);

          navigate("/employees/?status=save_as_draft");
        }, 2000);
      })
      .catch(function (error) {
        // handle error
        // setError(error);
        setTimeout(function () {
          setIsDisableDraft(false);
        }, 2000);
      });
  }

  const handleChangeSponsorShip = (e, input) => {
    input.onChange(sponsorship);
  };

  const required = (value) => (value ? undefined : "Required");

  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateAllFields}
      // initialValues={initialValues}
      //decorators={[focusOnError]}
      FormState
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        submitFailed,
        errors,
        form: { getState },
      }) => {
        setFormState(values);
        return (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <button
              id="mainFormSubmitEmployee"
              disabled={isDisableSubmit}
              type="submit"
            ></button>
            <button
              id="draftmainFormSubmitEmployee"
              type="button"
              disabled={isDisableDraft}
              onClick={() => saveAsDraft(getState().values)}
            ></button>
            <ErrorModification
              errors={errors}
              setCustomError={setCustomError}
            />
            <FormSpy
              subscription={{ submitFailed: true }}
              onChange={(v) => {
                const el = document.querySelector(".border-red-300");
                if (el) {
                  const parent = el.closest(".g-form-section");
                  if (parent) {
                    setCurrentTab(parent.dataset.tab);
                  }
                  el.scrollIntoView({ behavior: "smooth" });
                  //
                }
              }}
            />
            <FormTabs
              tabs={tabs}
              setCurrentTab={setCurrentTab}
              defaultTab={currentTab}
              values={values}
              initialRequiredField={initialRequiredField}
              formSelect={tabSelect}
              errors={errors}
              submitted={submitted}
              setSubmitted={setSubmitted}
              nonRegisterederror={nonRegisterederror}
            />

            <FormSection
              title=""
              tab="personal_information"
              isShow={currentTab === "personal_information"}
            >
              <Field name="name">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Name"
                    input={input}
                    meta={meta}
                    placeholder="Enter Name"
                    col={3}
                  />
                )}
              </Field>

              <Field name="name_arabic">
                {({ input, meta }) => (
                  <TextField
                    rtl={"direction: rtl"}
                    label="# Arabic Name"
                    input={input}
                    meta={meta}
                    placeholder="Enter Arabic Name"
                    col={3}
                  />
                )}
              </Field>

              <Field name="profile_pic_url">
                {({ input, meta }) => (
                  <GrandizerSingleFileUploadField
                    label="# Profile Pic"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>

              <Field name="gender">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input?.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Gender
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="dob">
                {({ input, meta }) => (
                  <DatePickerField
                    label="# D.O.B"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>

              <Field name="nationality">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input?.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Nationality <span className="text-red-400">*</span>
                    </label>
                    <Select
                      {...input}
                      options={nationalListing}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="nationality_ar">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Nationality (Arabic)
                    </label>
                    <Select
                      {...input}
                      options={nationalityArabicListing}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />

                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="res_country">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Resident Country <span className="text-red-400">*</span>
                    </label>
                    <Select
                      {...input}
                      options={countryListing}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="res_country_ar">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Resident Country (Arabic){" "}
                      <span className="text-red-400">*</span>
                    </label>
                    <Select
                      {...input}
                      options={nationalityArabicListing}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="hometown_address">
                {({ input, meta }) => (
                  <TextField
                    required={values.nationality?.value !== "Saudi" && true}
                    label="# Hometown Address"
                    input={input}
                    meta={meta}
                    placeholder="Enter Home Town Address"
                    value={values.nationality?.value !== "Saudi"}
                    col={3}
                  />
                )}
              </Field>

              <Field name="local_address">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Local Address"
                    input={input}
                    meta={meta}
                    placeholder="Enter Local Town Address"
                    col={3}
                  />
                )}
              </Field>

              <Field name="marital_status">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Marital Status
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              {values.marital_status === "Married" && (
                <>
                  {values?.gender !== "female" ? (
                    <Field name="number_of_wife" validate={required}>
                      {({ input, meta }) => (
                        <Numberfield
                          required={true}
                          label="# Number Of Wife"
                          input={input}
                          meta={meta}
                          placeholder=""
                          col={3}
                        />
                      )}
                    </Field>
                  ) : (
                    ""
                  )}

                  <Field name="number_of_children">
                    {({ input, meta }) => (
                      <Numberfield
                        label="# Number Of Childrens"
                        input={input}
                        meta={meta}
                        placeholder=""
                        col={3}
                      />
                    )}
                  </Field>
                </>
              )}

              {values.marital_status === "Divorced" && (
                <Field name="number_of_children">
                  {({ input, meta }) => (
                    <Numberfield
                      label="# Number Of Childrens"
                      input={input}
                      meta={meta}
                      placeholder=""
                      col={6}
                    />
                  )}
                </Field>
              )}
            </FormSection>
            <FormSection
              title=""
              tab="contact_information"
              isShow={currentTab === "contact_information"}
            >
              <Field name="email">
                {({ input, meta }) => (
                  <TextFieldEmail
                    required={true}
                    label="# Corporate's Email"
                    input={input}
                    meta={meta}
                    placeholder="Enter Corporate's Email"
                    errorHold={errorHold}
                    setErrorHold={setErrorHold}
                    col={3}
                  />
                )}
              </Field>
              <Field name="personal_email">
                {({ input, meta }) => (
                  <TextField
                    label="# Personal Email"
                    input={input}
                    meta={meta}
                    placeholder="Enter Personal Email"
                    col={3}
                  />
                )}
              </Field>
              <Field name="cellphone_number">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Cellphone Number <span className="text-red-400">*</span>
                    </label>
                    {/* <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="afghan">afghan</option>
                        <option value="albanian">albanian</option>
                        
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div> */}
                    <PhoneInput
                      {...input}
                      country={"sa"}
                      // value={this.state.phone}
                      // onChange={phone => this.setState({ phone })}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="alternative_mobile">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Alternative Mobile
                    </label>
                    <PhoneInput
                      {...input}
                      country={"sa"}
                      // value={this.state.phone}
                      // onChange={phone => this.setState({ phone })}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="emergency_contact_relationship">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Emergency Contact Relationship
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Sister">Sister</option>
                        <option value="Brother">Brother</option>
                        <option value="Wife">Wife</option>
                        <option value="Friend">Friend</option>
                        <option value="Other">Other</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="emergency_contact_name">
                {({ input, meta }) => (
                  <TextField
                    label="# Emergency Contact Name"
                    input={input}
                    meta={meta}
                    placeholder="Enter Emergency Contact Name"
                    col={3}
                  />
                )}
              </Field>
              <Field name="emergency_contact_mobile_number">
                {({ input, meta }) => (
                  <TextField
                    label="# Emergency Contact Mobile Number"
                    input={input}
                    meta={meta}
                    placeholder="Enter Emergency Contact Mobile Number"
                    col={6}
                  />
                )}
              </Field>
            </FormSection>
            <FormSection
              title=""
              tab="bank_passport_information"
              isShow={currentTab === "bank_passport_information"}
            >
              <Field name="passport_no">
                {({ input, meta }) => (
                  <TextField
                    label="# Passport No"
                    required={values.nationality?.value !== "Saudi" && true}
                    input={input}
                    meta={meta}
                    placeholder="Enter Passport No"
                    col={3}
                  />
                )}
              </Field>
              <Field name="passport_expire_date">
                {({ input, meta }) => (
                  <DatePickerField
                    label="# Passport_expire_date"
                    required={values.nationality?.value !== "Saudi" && true}
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>
              <Field name="passport_file">
                {({ input, meta }) => (
                  <GrandizerSingleFileUploadField
                    label="# Passport File"
                    required={values.nationality?.value !== "Saudi" && true}
                    col={3}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="iban_file">
                {({ input, meta }) => (
                  <GrandizerSingleFileUploadField
                    label="# IBAN File"
                    col={3}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="bank_accounts">
                {({ input, meta }) => (
                  <GrandizerArrayField
                    label="# Bank Accounts"
                    input={input}
                    meta={meta}
                    columns={[
                      { key: "bank_name", label: "Bank Name" },
                      { key: "iban", label: "Account Number" },
                    ]}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="company_information"
              isShow={currentTab === "company_information"}
            >
              <Field name="joining_date">
                {({ input, meta }) => (
                  <DatePickerField
                    required={true}
                    label="# Joining Date"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>

              <Field name="leaving_date">
                {({ input, meta }) => (
                  <DatePickerField
                    label="# Leaving Date"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>

              <Field name="company_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Company<span className="text-red-400">*</span>
                    </label>
                    <Select
                      {...input}
                      options={companyDpOptions}
                      onChange={(e) => handleChange(e, input)}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              {/* <Field name="Company_Location">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Company Location
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="1">Father</option>
                        <option value="2">Mother</option>
                        <option value="3">Sister</option>
                        <option value="4">Brother</option>
                        <option value="5">Wife</option>
                        <option value="6">Friend</option>
                        <option value="7">Other</option>

                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field> */}
              <Field name="company_location_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Company Location <span className="text-red-400">*</span>
                    </label>
                    <Select
                      {...input}
                      options={locationsOptions}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              {/* <Field name="sponsorship" validate={required}>
                {({ input, meta }) => (
                <div className="col-span-12 sm:col-span-3">
                <label
                  htmlFor={input.name}
                  className="block text-sm font-medium text-gray-700"
                >
                  # Sponsorship <span className='text-red-400'>*</span>
                </label>
                <input type='text' {...input} />
                {meta.error && meta.touched && (
                  <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                )}
              </div>
                )}
              </Field> */}

              <Field name="position_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Position <span className="text-red-400">*</span>
                    </label>
                    <Select
                      {...input}
                      options={positionOptions}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="department_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Department <span className="text-red-400">*</span>
                    </label>
                    <Select
                      ref={departmentREf}
                      {...input}
                      options={departmentOptions ?? []}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="division_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Division
                    </label>
                    <Select
                      {...input}
                      options={divisionOptions ?? []}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="job_title">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Job Title"
                    input={input}
                    meta={meta}
                    placeholder="Enter Job Title"
                    col={3}
                  />
                )}
              </Field>

              <Field name="job_title_ar">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    rtl={"direction: rtl"}
                    label="# Job Title (Arabic)"
                    input={input}
                    meta={meta}
                    placeholder="Enter Job Title (Arabic)"
                    col={3}
                  />
                )}
              </Field>

              <Field name="line_manager_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Line Manager
                      {/* <span className='text-red-400'>*</span> */}
                    </label>
                    <Select
                      {...input}
                      options={lineManagerOptions}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="roles">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Additional system roles to the Employee
                      {/* <span className='text-red-400'>*</span> */}
                    </label>
                    <Select
                      {...input}
                      isMulti
                      name="roles"
                      options={rolesOptions ?? []}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                )}
              </Field>

              <Field name="sponsorship">
                {({ input, meta }) => (
                  <SponserShipField
                    required={true}
                    placeholder="No sponsership..."
                    input={input}
                    meta={meta}
                    col={3}
                    values={values}
                    label="SponserShip"
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="iqama_medical_information"
              isShow={currentTab === "iqama_medical_information"}
            >
              <Field name="national_iqama_id">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# National / Iqama ID"
                    input={input}
                    meta={meta}
                    placeholder="Enter National / Iqama ID"
                    col={3}
                  />
                )}
              </Field>

              <Field name="national_iqama_id_expire_date">
                {({ input, meta }) => (
                  <DatePickerField
                    required={true}
                    label="# National / Iqama ID Expire Date"
                    input={input}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>

              <Field name="iqama_id_file">
                {({ input, meta }) => (
                  <GrandizerSingleFileUploadField
                    required={true}
                    label="# National / Iqama ID File"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>

              <Field name="medical_membership_no">
                {({ input, meta }) => (
                  <TextField
                    label="# Medical Membership No"
                    input={input}
                    meta={meta}
                    placeholder="Enter Medical Membership No"
                    col={3}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="salary_information"
              isShow={currentTab === "salary_information"}
            >
              <Field name="basic_salary" validate={required}>
                {({ input, meta }) => (
                  <TextFieldSalery
                    required={true}
                    label="# Salary Details"
                    salery="1 basic"
                    input={input}
                    meta={meta}
                    col={6}
                  />
                )}
              </Field>
              <Field name="housing_salary" validate={required}>
                {({ input, meta }) => (
                  <TextFieldSalery
                    required={true}
                    salery="2 housing"
                    input={input}
                    meta={meta}
                    col={6}
                  />
                )}
              </Field>

              <Field name="transpiration_salary" validate={required}>
                {({ input, meta }) => (
                  <TextFieldSalery
                    required={true}
                    salery="3 transpiration"
                    input={input}
                    meta={meta}
                    col={6}
                  />
                )}
              </Field>
              <Field name="salary_total">
                {({ input, meta }) => (
                  <TextFieldSaleryTotal
                    required={true}
                    salery="Total of"
                    input={input}
                    meta={meta}
                    col={6}
                    values={values}
                    placeholder="Total of"
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="working_hours"
              isShow={currentTab === "working_hours"}
            >
              {/* <Field name="Working_at_office">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Working at office
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="HQ">HQ</option>


                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field> */}

              {values.is_remote !== true && (
                <Field name="Working_at_office">
                  {({ input, meta }) => (
                    <div className="col-span-12 sm:col-span-12">
                      <label
                        htmlFor={input.name}
                        className="block text-sm font-medium text-gray-700"
                      >
                        # Working at office{" "}
                        <span className="text-red-400">*</span>
                      </label>
                      <Select
                        {...input}
                        options={officeOptions}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {meta.error && meta.touched && (
                        <p className="mt-2 text-sm text-red-600">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>
              )}

              {values.is_remote === true && (
                <>
                  <Field name="timezone" validate={required}>
                    {({ input, meta }) => (
                      <TimezoneSelectField
                        required={true}
                        label="Timezone"
                        input={input}
                        meta={meta}
                        col={3}
                      />
                    )}
                  </Field>

                  <Field name="close_payroll_day" validate={required}>
                    {({ input, meta }) => (
                      <div className="col-span-12 sm:col-span-6">
                        <label
                          htmlFor={input.name}
                          className="block text-sm font-medium text-gray-700"
                        >
                          # Close payroll day{" "}
                          <span className="text-red-400">*</span>
                        </label>

                        <div className="mt-1 relative rounded-md shadow-sm">
                          <select
                            {...input}
                            className={
                              meta.error && meta.touched
                                ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                            }
                          >
                            <option value="">Select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                          {meta.error && meta.touched && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {meta.error && meta.touched && (
                          <p className="mt-2 text-sm text-red-600">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="group_department_toggle">
                    {({ input, meta }) => (
                      <CustomToggleField
                        input={input}
                        meta={meta}
                        label="Allow 15 mins buffer for check-in & check-out timings to be considered as valid?"
                      />
                    )}
                  </Field>
                </>
              )}
              <Field name="sunday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggle
                    label="Sunday"
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>

              <Field name="monday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggle
                    label="Monday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="tuesday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggle
                    label="Tuesday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="wednesday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggle
                    label="Wednesday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="thursday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggle
                    label="Thursday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="friday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggle
                    label="Friday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="saturday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggle
                    label="Saturday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="job_type"
              isShow={currentTab === "job_type"}
            >
              <Field name="job_type" validate={required}>
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Contract Type <span className="text-red-400">*</span>
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select</option>
                        <option value="parttime">Part-Time</option>
                        <option value="fulltime">Full-Time</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="is_remote">
                {({ input, meta }) => (
                  <IsRemoteToggleFIeld input={input} meta={meta} />
                )}
              </Field>

              <Field name="contracts">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Year of Contract
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">year</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="contracts_file">
                {({ input, meta }) => (
                  <GrandizerSingleFileUploadField
                    label=""
                    requied={true}
                    col={3}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
            </FormSection>
            <FormSpy
              subscription={{ values: true }}
              component={FormPercentage}
              setRequiredField={initialValues}
              setButtonActive={props.savaButtonAction}
            />
          </form>
        );
      }}
    />
  );
}
