import Numberfield from "components/fields/numberfield";
import TextField from "components/fields/TextField";
import FormSection from "components/forms/FormSection";
import FormTabs from "components/forms/FormTabs";
import React, { useState, useEffect } from "react";
import { Form, Field, FormSpy, useForm } from "react-final-form";
import GrandizerSingleFileUploadField from "components/fields/GrandizerSingleFileUploadField";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import DatePickerField from "components/fields/DatePickerField";
import GrandizerArrayField from "../../../components/fields/GrandizerArrayField";
import WorkingHoursToggle from "components/fields/WorkingHoursToggle";
import FormPercentage from "components/forms/FormPercentage";
import TextFieldSalery from "components/fields/textfieldsalery";
import TextFieldSaleryTotal from "components/fields/textfieldsalerytotal";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import axios from "axios";
import Select from "react-select";
import { validateAllFields } from "../../../helpers/validationForEmployees";
import "react-phone-input-2/lib/style.css";
import {
  nationalityArabicListing,
  nationalListing,
  countryListing,
} from "constants/employyes/employee";
import { Switch } from "@headlessui/react";
import IsRemoteToggleFIeld from "components/fields/IsRemoteToggleFIeld";
import UpdateGrandizerSingleFileUploadField from "components/fields/UpdateGrandizerSingleFileUploadField";
import DatePicker from "react-date-picker";
import UpdateDatePickerField from "components/fields/UpdateDatePickerField";
import WorkingHoursToggleUpdate from "components/fields/WorkingHoursToggleUpdate";
import UpdateTextField from "components/fields/UpdateTextField";
import TimezoneSelectField from "components/fields/TimezoneSelectField";
import moment from "moment/moment";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import SponserShipField from "components/fields/sponsershipFIeld";
import UpdateSponserShipField from "components/fields/UpdateSponsershipField";
import CustomToggleField from "components/fields/customToggleField";
import ErrorModification from "features/company/components/ErrorModification";
import TextFieldEmail from "components/fields/TextFieldEmail";

export default function UpdateCrudForm(props) {
  const {
    onSubmit,
    initialValues,
    errorMsg,
    model,
    updateValues,
    setButtonActive,
    initialRequiredField,
    tabSelect,
    user,
    draftEmployeeId,
    isDisableDraft,
    setIsDisableDraft,
    errorHold,
    setErrorHold,
    setCustomError,
    submitted,
    setSubmitted,
    nonRegisterederror,
    isDisableSubmit,
  } = props;

  const [currentTab, setCurrentTab] = useState("personal_information");
  const [allRoles, setAllRoles] = useState([]);
  const [lineManager, setLineManager] = useState([]);
  const [companyDpt, setCompanyDpt] = useState([]);
  const [companyDPId, setCompanyDpId] = useState();
  const [first, setfirst] = useState();
  let navigate = useNavigate();

  if (first) {
    if (first?.is_remote === true) {
      updateValues["timezone"] = "";
      updateValues["close_payroll_day"] = "";
      initialRequiredField.working_hours["timezone"] = "";
      initialRequiredField.working_hours["close_payroll_day"] = "";
      delete initialRequiredField.working_hours?.Working_at_office;
      delete updateValues?.Working_at_office;
    }
    if (!first.is_remote || first?.is_remote === false) {
      updateValues["Working_at_office"] = "";
      initialRequiredField.working_hours["Working_at_office"] = "";
      delete updateValues?.timezone;
      delete updateValues?.close_payroll_day;
      delete initialRequiredField.working_hours?.timezone;
      delete initialRequiredField.working_hours?.close_payroll_day;
    }
  }

  if (first) {
    if (first?.nationality === "Saudi") {
      delete updateValues?.hometown_address;
      delete initialRequiredField.personal_information?.hometown_address;
      delete updateValues?.passport_no;
      delete initialRequiredField.bank_passport_information?.passport_no;
      delete updateValues?.passport_expire_date;
      delete initialRequiredField.bank_passport_information
        ?.passport_expire_date;
      delete updateValues?.passport_file;
      delete initialRequiredField.bank_passport_information?.passport_file;
    } else {
      updateValues["hometown_address"] = "";
      initialRequiredField.personal_information["hometown_address"] = "";
      updateValues["passport_no"] = "";
      initialRequiredField.bank_passport_information["passport_no"] = "";
      updateValues["passport_expire_date"] = "";
      initialRequiredField.bank_passport_information["passport_expire_date"] =
        "";
      updateValues["passport_file"] = null;
      initialRequiredField.bank_passport_information["passport_file"] = null;
    }
  }

  // useEffect(() => {

  // }, [updateValues])

  useEffect(() => {
    getRolesInfo();
  }, []);

  async function getRolesInfo() {
    await axios
      .get("role/all", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setAllRoles(response.data.data);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  useEffect(() => {
    getLineManger();
  }, []);

  async function getLineManger() {
    await axios
      .get("create/users", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setLineManager(response.data.data.users);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  useEffect(() => {
    getCompanyDepartment();
  }, []);

  async function getCompanyDepartment() {
    await axios
      .get("crete/companies-with-departments", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        // setSendingData(false);
        // resolve()

        setCompanyDpt(response.data.data?.departments);
      })
      .catch(function (error) {
        toast.error(error.response.data.message);
      });
  }

  const [departmentData, setDepartmentData] = useState();

  const roles = allRoles.map((item) => item);
  const lineMg = lineManager.map((item) => item);
  const companyDpOptions = companyDpt.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const removeASuperAdmin = roles.filter((item) => item.name !== "Super Admin");

  const rolesOptions = removeASuperAdmin.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));
  const lineManagerOptions = lineMg.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const departmentOptions = departmentData?.department?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));
  const locationsOptions = departmentData?.location?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const officeOptions = departmentData?.office?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const positionOptions = departmentData?.position?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const divisionOptions = departmentData?.division?.map((item) => ({
    id: item.id,
    value: item.name,
    label: item.name,
  }));

  const tabs = [
    {
      name: (
        <span>
          Personal Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "personal_information",
      bgColor: null,
      fields: [
        {
          name: "name",
        },
        {
          name: "name_arabic",
        },
        {
          name: "profile_pic_url",
        },
        {
          name: "gender",
        },
        {
          name: "dob",
        },
        {
          name: "nationality",
        },
        {
          name: "nationality_ar",
        },
        {
          name: "res_country",
        },
        {
          name: "res_country_ar",
        },
        {
          name: "hometown_address",
        },
        {
          name: "local_address",
        },
        {
          name: "marital_status",
        },
        {
          name: "number_of_wife",
        },
        {
          name: "number_of_children",
        },
      ],
    },

    // { name: "Address", key: "address" },
    {
      name: (
        <span>
          Contact Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "contact_information",
      bgColor: null,
      fields: [
        {
          name: "email",
        },
        {
          name: "personal_email",
        },
        {
          name: "cellphone_number",
        },
        {
          name: "alternative_mobile",
        },
        {
          name: "emergency_contact_relationship",
        },
        {
          name: "emergency_contact_name",
        },
        {
          name: "emergency_contact_mobile_number",
        },
      ],
    },

    {
      name: (
        <span>
          Bank & Passport Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "bank_passport_information",
      bgColor: null,
      fields: [
        {
          name: "passport_no",
        },
        {
          name: "passport_expire_date",
        },
        {
          name: "passport_file",
        },
        {
          name: "iban_fileurl",
        },
        {
          name: "bank_accounts",
          label: "# Bank Accounts",
          component: GrandizerArrayField,
          props: {
            keyValue: "updateEmployee",
            columns: [
              { key: "bank_name", label: "Bank Name" },
              { key: "iban", label: "Account Number" },
            ],
          },
        },
      ],
    },
    {
      name: (
        <span>
          Company Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "company_information",
      bgColor: null,

      fields: [
        {
          name: "joining_date",
        },
        {
          name: "leaving_date",
        },
        {
          name: "company_id",
        },
        {
          name: "company_location_id",
        },
        {
          name: "position_id",
        },
        {
          name: "department_id",
        },
        {
          name: "division_id",
        },
        {
          name: "job_title",
        },
        {
          name: "job_title_ar",
        },
        {
          name: "line_manager_id",
        },
        {
          name: "system_roles",
        },
        {
          name: "sponsorship",
        },
      ],
    },

    // { name: "Documents", key: "documents" },
    // { name: "Bank Detail", key: "bank_detail" },
    {
      name: (
        <span>
          Iqama & Medical Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "iqama_medical_information",
      bgColor: null,
      fields: [
        {
          name: "national_iqama_id",
        },
        {
          name: "national_iqama_id_expire_date",
        },
        {
          name: "iqama_id_file",
        },
        {
          name: "medical_membership_no",
        },
      ],
    },
    {
      name: (
        <span>
          Salary Information<span className="text-red-500"> * </span>
        </span>
      ),
      key: "salary_information",
      bgColor: null,
      fields: [
        {
          name: "basic_salary",
        },
        {
          name: "housing_salary",
        },
        {
          name: "transpiration_salary",
        },
        {
          name: "salary_total",
        },
      ],
    },
    {
      name: (
        <span>
          Working Hours<span className="text-red-500"> * </span>
        </span>
      ),
      key: "working_hours",
      bgColor: null,
      fields: [
        {
          name: "Working_at_office",
        },
        {
          name: "timezone",
        },
        {
          name: "close_payroll_day",
        },
        {
          name: "group_department_toggle",
        },
        {
          name: "sunday_timing",
        },
        {
          name: "monday_timing",
        },
        {
          name: "tuesday_timing",
        },
        {
          name: "wednesday_timing",
        },
        {
          name: "thursday_timing",
        },
        {
          name: "friday_timing",
        },
        {
          name: "saturday_timing",
        },
      ],
    },

    {
      name: (
        <span>
          Job Type<span className="text-red-500"> * </span>
        </span>
      ),
      key: "job_type",
      bgColor: null,
      fields: [
        {
          name: "job_type",
        },
        {
          name: "is_remote",
        },
        {
          name: "contracts",
        },
        {
          name: "contracts_file",
        },
      ],
    },
  ];

  let nationalDefaultabc = nationalListing?.filter(
    (item) => item.value === initialValues?.nationality
  );
  let nationalArabicDefaultabc = nationalityArabicListing?.filter(
    (item) => item.value === initialValues?.nationality_ar
  );

  let resCountryDefaultabc = countryListing?.filter(
    (item) => item.value === initialValues?.res_country
  );
  let resCountryArabicDefaultabc = nationalityArabicListing?.filter(
    (item) => item.value === initialValues?.res_country_ar
  );

  let companyDefaultabc = companyDpOptions?.find(
    (item) => item.id === first?.company_id
  );
  let departmentDefaultabc = departmentOptions?.find(
    (item) => item.id === first?.department_id
  );
  let comlocationDefaultabc = locationsOptions?.find(
    (item) => item.id === first?.company_location_id
  );

  let positionDefaultabc = positionOptions?.find(
    (item) => item.id === first?.position_id
  );

  let officeDefaultabc = officeOptions?.find(
    (item) => item?.id == first?.Working_at_office
  );

  let divisionDefaultabc = divisionOptions?.find(
    (item) => item.id === first?.division_id
  );

  let rolesDefaultabc = rolesOptions?.filter((item) =>
    initialValues?.system_roles?.some((item2) => item.id === item2.id)
  );

  let managerDefaultabc = lineManagerOptions?.find(
    (item) => item.id === initialValues?.line_manager_id
  );

  const [randomid, setRandomid] = useState();

  useEffect(() => {
    setRandomid(initialValues?.company_id);
  }, [initialValues]);

  useEffect(() => {
    getSpecificCompanyDepartment();
  }, [first?.company_id]);

  async function getSpecificCompanyDepartment() {
    if (first?.company_id) {
      await axios
        .get("company/department/" + first?.company_id, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          // setSendingData(false);
          // resolve()

          // setDepartmentData(Object?.values(response.data.data?.department));
          const responseData = response.data.data;

          setDepartmentData(responseData);

          // const selectedValueInResponse = responseData.department?.some(item => item.name === selectedValue);

          // // update the dropdown value if necessary
          // if (departmentREf.current && !selectedValueInResponse) {
          //   departmentREf.current.value = null;
          //   setSelectedValue(null);
          // }
        })
        .catch(function (error) {
          toast.error(error.response?.data?.message);
        });
    }
  }

  const [nationalDefault, setNationalDefault] = useState({
    nationalValue: nationalDefaultabc,
  });

  const [nationalArabicDefault, setNationalArabicDefault] = useState({
    nationalArabicValue: nationalArabicDefaultabc,
  });

  const [resCountryDefault, setResCountryDefault] = useState({
    resCountryValue: resCountryDefaultabc,
  });

  const [resCountryArabicDefault, setResCountryArabicDefault] = useState({
    resCountryArabicValue: resCountryArabicDefaultabc,
  });

  const [companyDefault, setCompanyDefault] = useState({
    companyDefaultValue: companyDefaultabc,
  });

  const [departmentDefault, setDepartmentDefault] = useState({
    departmentDefaultValue: departmentDefaultabc,
  });

  const [managerDefault, setManagerDefault] = useState({
    managerDefaultValue: managerDefaultabc,
  });

  const [comLocationDefault, setComLocationDefault] = useState({
    comLocationDefaultValue: comlocationDefaultabc,
  });

  const [rolesDefault, setRolesDefault] = useState({
    rolesDefaultValue: rolesDefaultabc,
  });

  const [officeDefault, setOfficeDefault] = useState({
    officeDefaultValue: officeDefaultabc,
  });

  const [positionDefault, setPositionDefault] = useState({
    positionDefaultValue: positionDefaultabc,
  });

  const [divisionDefault, setDivisionDefault] = useState({
    divisionDefaultValue: divisionDefaultabc,
  });

  const [dob, setDob] = useState();

  useEffect(() => {
    setDob(initialValues?.dob);
  }, [initialValues?.dob]);

  const [leavingDate, setLeavingDate] = useState();

  useEffect(() => {
    setLeavingDate(model?.user_profile?.leaving_date);
  }, [model?.user_profile?.leaving_date]);

  const [gender, setGender] = useState();

  useEffect(() => {
    setGender(model?.user_profile?.gender);
  }, [model?.user_profile?.gender]);

  const handleGenderChange = (e, input) => {
    input.onChange(e.target.value);
    setGender(e.target.value);
  };

  const [maritStatus, setMaritStatus] = useState();

  useEffect(() => {
    setMaritStatus(model?.user_profile?.marital_status);
  }, [model?.user_profile?.marital_status]);

  const handleMaritChange = (e, input) => {
    input.onChange(e.target.value);
    setMaritStatus(e.target.value);
  };

  const [alternateNumber, setAlternateNumber] = useState();

  useEffect(() => {
    setAlternateNumber(model?.user_profile?.alternative_mobile);
  }, [model?.user_profile?.alternative_mobile]);

  const handleAlternateNUmberChange = (e, input) => {
    input.onChange(e);
    setAlternateNumber(e);
  };

  const [emergencyRel, setEmergencyRel] = useState();

  useEffect(() => {
    setEmergencyRel(model?.user_profile?.emergency_contact_relationship);
  }, [model?.user_profile?.emergency_contact_relationship]);

  const handleEmergencyRelChange = (e, input) => {
    input.onChange(e.target.value);
    setEmergencyRel(e.target.value);
  };

  useEffect(() => {
    setNationalDefault({
      ...nationalDefault,
      nationalValue: nationalDefaultabc,
    });
  }, [initialValues?.nationality]);

  useEffect(() => {
    setNationalArabicDefault({
      ...nationalArabicDefault,
      nationalArabicValue: nationalArabicDefaultabc,
    });
  }, [initialValues?.nationality_ar]);

  useEffect(() => {
    setResCountryDefault({
      ...resCountryDefault,
      resCountryValue: resCountryDefaultabc,
    });
  }, [initialValues?.res_country]);

  useEffect(() => {
    setResCountryArabicDefault({
      ...resCountryArabicDefault,
      resCountryArabicValue: resCountryArabicDefaultabc,
    });
  }, [initialValues?.res_country_ar]);

  useEffect(() => {
    setCompanyDefault({
      ...companyDefault,
      companyDefaultValue: companyDefaultabc,
    });
  }, [initialValues]);

  useEffect(() => {
    setComLocationDefault({
      ...comLocationDefault,
      comLocationDefaultValue: comlocationDefaultabc,
    });
  }, [initialValues]);

  useEffect(() => {
    setDepartmentDefault({
      ...departmentDefault,
      departmentDefaultValue: departmentDefaultabc,
    });
  }, [initialValues]);

  useEffect(() => {
    setOfficeDefault({
      ...officeDefault,
      officeDefaultValue: officeDefaultabc,
    });
  }, [initialValues]);

  useEffect(() => {
    setPositionDefault({
      ...positionDefault,
      positionDefaultValue: positionDefaultabc,
    });
  }, [initialValues]);

  useEffect(() => {
    setDivisionDefault({
      ...divisionDefault,
      divisionDefaultValue: divisionDefaultabc,
    });
  }, [initialValues]);

  useEffect(() => {
    setManagerDefault({
      ...managerDefault,
      managerDefaultValue: managerDefaultabc,
    });
  }, [initialValues]);

  useEffect(() => {
    setRolesDefault({ ...rolesDefault, rolesDefaultValue: rolesDefaultabc });
  }, [initialValues?.system_roles]);

  const required = (value) => (value ? undefined : "Required");

  const handleNationalChange = (e, input) => {
    setNationalDefault({
      ...nationalDefault,
      nationalValue: { value: e?.value, label: e?.label },
    });
    input.onChange(e?.value);
  };

  const handleNationalArabicChange = (e, input) => {
    setNationalArabicDefault({
      ...nationalArabicDefault,
      nationalArabicValue: { value: e?.value, label: e?.label },
    });
    input.onChange(e?.value);
  };

  const handleResCountryChange = (e, input) => {
    setResCountryDefault({
      ...resCountryDefault,
      resCountryValue: { value: e?.value, label: e?.label },
    });
    input.onChange(e?.value);
  };

  const handleResCountryArabicChange = (e, input) => {
    setResCountryArabicDefault({
      ...resCountryArabicDefault,
      resCountryArabicValue: { value: e?.value, label: e?.label },
    });
    input.onChange(e?.value);
  };

  const handleCompanyChange = (e, input) => {
    setCompanyDefault({
      ...companyDefault,
      companyDefaultValue: { id: e?.id, value: e?.value, label: e?.label },
    });
    setRandomid(e?.id);
    first.company_location_id = "";
    first.department_id = "";
    first.position_id = "";
    first.division_id = "";
    input.onChange(e?.id);
  };

  const handleDepartmentChange = (e, input) => {
    setDepartmentDefault({
      ...departmentDefault,
      departmentDefaultValue: { id: e?.id, value: e?.value, label: e?.label },
    });
    input.onChange(e?.id);
  };

  const handleOfficeChange = (e, input) => {
    setOfficeDefault({
      officeDefaultValue: { id: e?.id, value: e?.value, label: e?.label },
    });
    input.onChange(e?.id);
  };

  const handlePositionChange = (e, input) => {
    setPositionDefault({
      ...positionDefault,
      positionDefaultValue: { id: e?.id, value: e?.value, label: e?.label },
    });
    input.onChange(e?.id);
  };

  const handleDivisionChange = (e, input) => {
    setDivisionDefault({
      ...divisionDefault,
      divisionDefaultValue: { id: e?.id, value: e?.value, label: e?.label },
    });
    input.onChange(e?.id);
  };

  const handleManagerChange = (e, input) => {
    setManagerDefault({
      ...managerDefault,
      managerDefaultValue: { id: e?.id, value: e?.value, label: e?.label },
    });
    input.onChange(e?.id);
  };

  const handleComLocationChange = (e, input) => {
    setComLocationDefault({
      ...comLocationDefault,
      comLocationDefaultValue: { id: e?.id, value: e?.value, label: e?.label },
    });
    input.onChange(e?.id);
  };

  const handleRolesChange = (e, input) => {
    setRolesDefault({ ...rolesDefault, rolesDefaultValue: e });
    input.onChange(e);
  };

  function saveAsDraft(values) {
    setIsDisableDraft(true);
    const roleIds = values?.system_roles?.map((item) => parseInt(item.id));

    const businessHours = {
      friday: [
        values.friday_timing
          ? values.friday_timing
          : { open: null, close: null },
      ],
      saturday: [
        values.saturday_timing
          ? values?.saturday_timing
          : { open: null, close: null },
      ],
      sunday: [
        values.sunday_timing
          ? values.sunday_timing
          : { open: null, close: null },
      ],
      monday: [
        values.monday_timing
          ? values.monday_timing
          : { open: null, close: null },
      ],
      tuesday: [
        values.tuesday_timing
          ? values.tuesday_timing
          : { open: null, close: null },
      ],
      wednesday: [
        values.wednesday_timing
          ? values.wednesday_timing
          : { open: null, close: null },
      ],
      thursday: [
        values.thursday_timing
          ? values.thursday_timing
          : { open: null, close: null },
      ],
    };

    const profile_pic_url = {
      fileUrl: values.profile_pic_url?.fileUrl,
      fileName: values.profile_pic_url?.fileName,
    };

    const contracts_file = {
      fileUrl: values.contracts?.[0]?.file_url,
      fileName: values.contracts?.[0]?.file_name,
    };

    const passportFile = {
      fileUrl: values.profile_pic_url?.fileUrl,
      fileName: values.profile_pic_url?.fileName,
    };

    const finalDraftData = {
      ...values,
      profile_pic_url: values?.profile_pic_url?.fileUrl,
      profilepic_filename: values?.profile_pic_url?.fileName,
      passport_file: values?.passport_file?.fileUrl,
      passport_filename: values.passport_file?.fileName,
      national_iqama_id_fileurl: values.iqama_id_file?.fileUrl,
      national_iqama_id_filename: values.iqama_id_file?.fileName,
      contracts_file: values.contracts_file?.fileUrl,
      contracts_fileName: values.contracts_file?.fileName,
      roles: JSON.stringify(roleIds),
    };

    const formData = new FormData();

    formData.append("user-id", user.id);
    formData.append("model", "employee");
    formData.append("draft_id", draftEmployeeId);
    formData.append("data", JSON.stringify(finalDraftData));
    axios
      .post("draft/store", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        //  setUsersData(response.data.data.users);
        toast.success("Draft save!");
        setTimeout(function () {
          setIsDisableDraft(false);
          navigate("/employees/?status=save_as_draft");
        }, 2000);
      })
      .catch(function (error) {
        setTimeout(function () {
          setIsDisableDraft(false);
        }, 2000);
        // handle error
        // setError(error);
      });
  }
  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validateAllFields}
      // initialValues={initialValues}
      //decorators={[focusOnError]}
      FormState
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        submitFailed,
        errors,
        form: { getState },
      }) => {
        setfirst(values);
        // const formState = getState();
        // const errors = formState.errors;

        return (
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <button
              id="mainFormSubmitEmployee"
              disabled={isDisableSubmit}
              type="submit"
            ></button>
            <button
              id="draftmainFormSubmitEmployee"
              type="button"
              disabled={isDisableDraft}
              onClick={() => saveAsDraft(getState().values)}
            ></button>
            <ErrorModification
              errors={errors}
              setCustomError={setCustomError}
            />
            <FormSpy
              subscription={{ submitFailed: true }}
              onChange={(v) => {
                const el = document.querySelector(".border-red-300");
                if (el) {
                  const parent = el.closest(".g-form-section");
                  if (parent) {
                    setCurrentTab(parent.dataset.tab);
                  }
                  el.scrollIntoView({ behavior: "smooth" });
                  //
                }
              }}
            />
            <FormTabs
              tabs={tabs}
              setCurrentTab={setCurrentTab}
              defaultTab={currentTab}
              values={values}
              initialRequiredField={initialRequiredField}
              formSelect={tabSelect}
              errors={errors}
              submitted={submitted}
              setSubmitted={setSubmitted}
              nonRegisterederror={nonRegisterederror}
            />

            <FormSection
              title=""
              tab="personal_information"
              isShow={currentTab === "personal_information"}
            >
              <Field name="name">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Name"
                    input={input}
                    meta={meta}
                    placeholder="Enter Name"
                    col={3}
                  />
                )}
              </Field>

              <Field name="name_arabic">
                {({ input, meta }) => (
                  <TextField
                    rtl={"direction: rtl"}
                    // required={true}
                    label="# Arabic Name"
                    input={input}
                    meta={meta}
                    placeholder="Enter Arabic Name"
                    col={3}
                  />
                )}
              </Field>

              <Field name="profile_pic_url">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# Profile Pic"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>

              <Field name="gender">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input?.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Gender
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="dob">
                {({ input, meta }) => (
                  <UpdateDatePickerField
                    label="# D.O.B"
                    input={input}
                    data={dob}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>

              <Field name="nationality">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input?.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Nationality <span className="text-red-400">*</span>
                    </label>

                    <Select
                      name="nationalValue"
                      value={nationalDefault?.nationalValue}
                      onChange={(e) => handleNationalChange(e, input)}
                      options={nationalListing}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {(meta.error || meta.touched) && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="nationality_ar">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Nationality (Arabic)
                    </label>
                    <Select
                      name="nationalArabicValue"
                      value={nationalArabicDefault.nationalArabicValue}
                      onChange={(e) => handleNationalArabicChange(e, input)}
                      options={nationalityArabicListing}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />

                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="res_country">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Resident Country <span className="text-red-400">*</span>
                    </label>
                    <Select
                      name="resCountryValue"
                      value={resCountryDefault.resCountryValue}
                      onChange={(e) => handleResCountryChange(e, input)}
                      options={countryListing}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {(meta.error || meta.touched) && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="res_country_ar">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Resident Country (Arabic){" "}
                      <span className="text-red-400">*</span>
                    </label>
                    <Select
                      name="resCountryArabicValue"
                      value={resCountryArabicDefault.resCountryArabicValue}
                      onChange={(e) => handleResCountryArabicChange(e, input)}
                      options={nationalityArabicListing}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {(meta.error || meta.touched) && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="hometown_address">
                {({ input, meta }) => (
                  <TextField
                    required={values.nationality !== "Saudi" && true}
                    label="# Hometown Address"
                    input={input}
                    meta={meta}
                    placeholder="Enter Home Town Address"
                    value={values.nationality !== "Saudi"}
                    col={3}
                  />
                )}
              </Field>

              <Field name="local_address">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Local Address"
                    input={input}
                    meta={meta}
                    placeholder="Enter Local Town Address"
                    col={3}
                  />
                )}
              </Field>

              <Field name="marital_status">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Marital Status
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="Single">Single</option>
                        <option value="Married">Married</option>
                        <option value="Divorced">Divorced</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              {values.marital_status === "Married" ? (
                <>
                  <Field name="number_of_wife">
                    {({ input, meta }) => (
                      <Numberfield
                        // required={true}
                        label="# Number Of Wife"
                        input={input}
                        meta={meta}
                        placeholder=""
                        col={3}
                      />
                    )}
                  </Field>

                  <Field name="number_of_children">
                    {({ input, meta }) => (
                      <Numberfield
                        label="# Number Of Childrens"
                        input={input}
                        meta={meta}
                        placeholder=""
                        col={3}
                      />
                    )}
                  </Field>
                </>
              ) : (
                ""
              )}

              {values.marital_status === "Divorced" ? (
                <Field name="number_of_children">
                  {({ input, meta }) => (
                    <Numberfield
                      label="# Number Of Childrens"
                      input={input}
                      meta={meta}
                      placeholder=""
                      col={6}
                    />
                  )}
                </Field>
              ) : (
                ""
              )}
            </FormSection>
            <FormSection
              title=""
              tab="contact_information"
              isShow={currentTab === "contact_information"}
            >
              <Field name="email">
                {({ input, meta }) => (
                  <TextFieldEmail
                    label="# Corporate's Email"
                    required={true}
                    input={input}
                    meta={meta}
                    placeholder="Enter Corporate's Email"
                    col={3}
                    errorHold={errorHold}
                    setErrorHold={setErrorHold}
                  />
                )}
              </Field>
              <Field name="personal_email">
                {({ input, meta }) => (
                  <TextField
                    label="# Personal Email"
                    input={input}
                    meta={meta}
                    placeholder="Enter Personal Email"
                    col={3}
                  />
                )}
              </Field>
              <Field name="cellphone_number" validate={required}>
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Cellphone Number <span className="text-red-400">*</span>
                    </label>
                    <PhoneInput
                      {...input}
                      country={"sa"}
                      // value={this.state.phone}
                      // onChange={phone => this.setState({ phone })}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="alternative_mobile">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Alternative Mobile
                    </label>
                    <PhoneInput
                      {...input}
                      country={"sa"}
                      // value={this.state.phone}
                      // onChange={phone => this.setState({ phone })}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="emergency_contact_relationship">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Emergency Contact Relationship
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="Father">Father</option>
                        <option value="Mother">Mother</option>
                        <option value="Sister">Sister</option>
                        <option value="Brother">Brother</option>
                        <option value="Wife">Wife</option>
                        <option value="Friend">Friend</option>
                        <option value="Other">Other</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="emergency_contact_name">
                {({ input, meta }) => (
                  <TextField
                    label="# Emergency Contact Name"
                    input={input}
                    meta={meta}
                    placeholder="Enter Emergency Contact Name"
                    col={3}
                  />
                )}
              </Field>
              <Field name="emergency_contact_mobile_number">
                {({ input, meta }) => (
                  <TextField
                    label="# Emergency Contact Mobile Number"
                    input={input}
                    meta={meta}
                    placeholder="Enter Emergency Contact Mobile Number"
                    col={6}
                  />
                )}
              </Field>
            </FormSection>
            <FormSection
              title=""
              tab="bank_passport_information"
              isShow={currentTab === "bank_passport_information"}
            >
              <Field name="passport_no">
                {({ input, meta }) => (
                  <TextField
                    label="# Passport No"
                    required={values.nationality !== "Saudi" && true}
                    input={input}
                    meta={meta}
                    placeholder="Enter Passport No"
                    col={3}
                  />
                )}
              </Field>
              <Field name="passport_expire_date">
                {({ input, meta }) => (
                  <UpdateDatePickerField
                    label="# Passport_expire_date"
                    required={values.nationality !== "Saudi" && true}
                    input={input}
                    data={input.value}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>
              <Field
                name="passport_file"
                validate={values.nationality !== "Saudi" && required}
              >
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# Passport File"
                    required={values.nationality !== "Saudi" && true}
                    col={3}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="iban_fileurl">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label="# IBAN File"
                    col={3}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
              <Field name="bank_accounts">
                {({ input, meta }) => (
                  <GrandizerArrayField
                    label="# Bank Accounts"
                    input={input}
                    meta={meta}
                    keyValue="updateEmployee"
                    columns={[
                      { key: "bank_name", label: "Bank Name" },
                      { key: "iban", label: "Account Number" },
                    ]}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="company_information"
              isShow={currentTab === "company_information"}
            >
              <Field name="joining_date">
                {({ input, meta }) => (
                  <UpdateDatePickerField
                    required={true}
                    label="# Joining Date"
                    input={input}
                    data={input.value}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>

              <Field name="leaving_date">
                {({ input, meta }) => (
                  <UpdateDatePickerField
                    label="# Leaving Date"
                    input={input}
                    meta={meta}
                    data={input.value}
                    col={3}
                  />
                )}
              </Field>

              <Field name="company_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Company<span className="text-red-400">*</span>
                    </label>
                    <Select
                      name="companyDefaultValue"
                      value={companyDefault.companyDefaultValue}
                      onChange={(e) => handleCompanyChange(e, input)}
                      options={companyDpOptions}
                    />
                    {(meta.error || meta.touched) && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="company_location_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Company Location<span className="text-red-400">*</span>
                    </label>
                    <Select
                      name="comLocationDefaultValue"
                      value={comLocationDefault.comLocationDefaultValue}
                      onChange={(e) => handleComLocationChange(e, input)}
                      options={locationsOptions}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {(meta.error || meta.touched) && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="position_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Position <span className="text-red-400">*</span>
                    </label>
                    <Select
                      name="positionDefaultValue"
                      value={positionDefault.positionDefaultValue}
                      onChange={(e) => handlePositionChange(e, input)}
                      options={positionOptions ?? []}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {(meta.error || meta.touched) && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="department_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Department<span className="text-red-400">*</span>
                    </label>
                    <Select
                      name="departmentDefaultValue"
                      value={departmentDefault?.departmentDefaultValue}
                      onChange={(e) => handleDepartmentChange(e, input)}
                      options={departmentOptions ?? []}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {(meta.error || meta.touched) && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="division_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Division
                    </label>
                    <Select
                      name="divisionDefaultValue"
                      value={divisionDefault.divisionDefaultValue}
                      onChange={(e) => handleDivisionChange(e, input)}
                      options={divisionOptions ?? []}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>

              <Field name="job_title">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# Job Title"
                    input={input}
                    meta={meta}
                    placeholder="Enter Job Title"
                    col={3}
                  />
                )}
              </Field>

              <Field name="job_title_ar">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    rtl={"direction: rtl"}
                    label="# Job Title (Arabic)"
                    input={input}
                    meta={meta}
                    placeholder="Enter Job Title (Arabic)"
                    col={3}
                  />
                )}
              </Field>

              <Field name="line_manager_id">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Line Manager
                      {/* <span className='text-red-400'>*</span> */}
                    </label>
                    <Select
                      name="managerDefaultValue"
                      value={managerDefault.managerDefaultValue}
                      onChange={(e) => handleManagerChange(e, input)}
                      options={lineManagerOptions}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="system_roles">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Additional system roles to the Employee
                      {/* <span className='text-red-400'>*</span> */}
                    </label>
                    <Select
                      meta={meta}
                      input={input}
                      isMulti
                      name="rolesDefaultValue"
                      value={rolesDefault.rolesDefaultValue}
                      onChange={(e) => handleRolesChange(e, input)}
                      options={rolesOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                  </div>
                )}
              </Field>

              <Field name="sponsorship">
                {({ input, meta }) => (
                  <UpdateSponserShipField
                    required={true}
                    placeholder="No sponsership..."
                    input={input}
                    meta={meta}
                    col={3}
                    value={companyDefault.companyDefaultValue}
                    // values={values}
                    name="companyDefaultValue"
                    label="SponserShip"
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="iqama_medical_information"
              isShow={currentTab === "iqama_medical_information"}
            >
              <Field name="national_iqama_id">
                {({ input, meta }) => (
                  <TextField
                    required={true}
                    label="# National / Iqama ID"
                    input={input}
                    meta={meta}
                    placeholder="Enter National / Iqama ID"
                    col={3}
                  />
                )}
              </Field>

              <Field name="national_iqama_id_expire_date">
                {({ input, meta }) => (
                  <UpdateDatePickerField
                    required={true}
                    label="# National / Iqama ID Expire Date"
                    input={input}
                    data={input.value}
                    meta={meta}
                    col={3}
                  />
                )}
              </Field>

              <Field name="iqama_id_file" validate={required}>
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    required={true}
                    label="# National / Iqama ID File"
                    col={6}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>

              <Field name="medical_membership_no">
                {({ input, meta }) => (
                  <TextField
                    label="# Medical Membership No"
                    input={input}
                    meta={meta}
                    placeholder="Enter Medical Membership No"
                    col={3}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="salary_information"
              isShow={currentTab === "salary_information"}
            >
              <Field name="basic_salary" validate={required}>
                {({ input, meta }) => (
                  <TextFieldSalery
                    required={true}
                    label="# Salary Details"
                    salery="1 basic"
                    input={input}
                    meta={meta}
                    col={6}
                  />
                )}
              </Field>
              <Field name="housing_salary" validate={required}>
                {({ input, meta }) => (
                  <TextFieldSalery
                    required={true}
                    salery="2 housing"
                    input={input}
                    meta={meta}
                    col={6}
                  />
                )}
              </Field>

              <Field name="transpiration_salary" validate={required}>
                {({ input, meta }) => (
                  <TextFieldSalery
                    required={true}
                    salery="3 transpiration"
                    input={input}
                    meta={meta}
                    col={6}
                  />
                )}
              </Field>
              <Field name="salary_total">
                {({ input, meta }) => (
                  <TextFieldSaleryTotal
                    required={true}
                    salery="Total of"
                    input={input}
                    meta={meta}
                    col={6}
                    values={values}
                    placeholder="Total of"
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="working_hours"
              isShow={currentTab === "working_hours"}
            >
              {/* <Field name="Working_at_office">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Working at office
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select...</option>
                        <option value="HQ">HQ</option>


                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field> */}

              {values.is_remote !== true && (
                <Field name="Working_at_office">
                  {({ input, meta }) => (
                    <div className="col-span-12 sm:col-span-12">
                      <label
                        htmlFor={input.name}
                        className="block text-sm font-medium text-gray-700"
                      >
                        # Working at office{" "}
                        <span className="text-red-400">*</span>
                      </label>
                      <Select
                        name="officeDefaultValue"
                        value={officeDefault.officeDefaultValue}
                        onChange={(e) => handleOfficeChange(e, input)}
                        options={officeOptions}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {meta.error && meta.touched && (
                        <p className="mt-2 text-sm text-red-600">
                          {meta.error}
                        </p>
                      )}
                    </div>
                  )}
                </Field>
              )}

              {values.is_remote === true && (
                <>
                  <Field name="timezone" validate={required}>
                    {({ input, meta }) => (
                      <TimezoneSelectField
                        required={true}
                        label="Timezone"
                        input={input}
                        meta={meta}
                        col={3}
                      />
                    )}
                  </Field>

                  <Field name="close_payroll_day" validate={required}>
                    {({ input, meta }) => (
                      <div className="col-span-12 sm:col-span-6">
                        <label
                          htmlFor={input.name}
                          className="block text-sm font-medium text-gray-700"
                        >
                          # Close payroll day{" "}
                          <span className="text-red-400">*</span>
                        </label>

                        <div className="mt-1 relative rounded-md shadow-sm">
                          <select
                            {...input}
                            className={
                              meta.error && meta.touched
                                ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                                : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                            }
                          >
                            <option value="">Select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                          {meta.error && meta.touched && (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ExclamationCircleIcon
                                className="h-5 w-5 text-red-500"
                                aria-hidden="true"
                              />
                            </div>
                          )}
                        </div>
                        {meta.error && meta.touched && (
                          <p className="mt-2 text-sm text-red-600">
                            {meta.error}
                          </p>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="group_department_toggle">
                    {({ input, meta }) => (
                      <CustomToggleField
                        input={input}
                        meta={meta}
                        label="Allow 15 mins buffer for check-in & check-out timings to be considered as valid?"
                      />
                    )}
                  </Field>
                </>
              )}

              <Field name="sunday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggleUpdate
                    label="Sunday"
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>

              <Field name="monday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggleUpdate
                    label="Monday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="tuesday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggleUpdate
                    label="Tuesday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="wednesday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggleUpdate
                    label="Wednesday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="thursday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggleUpdate
                    label="Thursday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="friday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggleUpdate
                    label="Friday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>

              <Field name="saturday_timing">
                {({ input, meta }) => (
                  <WorkingHoursToggleUpdate
                    label="Saturday"
                    input={input}
                    meta={meta}
                    // columns={[
                    //   { key: "bank_name", label: "Bank Name" },
                    //   { key: "account_number", label: "Account Number" },
                    // ]}
                  />
                )}
              </Field>
            </FormSection>

            <FormSection
              title=""
              tab="job_type"
              isShow={currentTab === "job_type"}
            >
              <Field name="job_type">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-6">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Contract Type <span className="text-red-400">*</span>
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">Select</option>
                        <option value="parttime">Part-Time</option>
                        <option value="fulltime">Full-Time</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="is_remote">
                {({ input, meta }) => (
                  <IsRemoteToggleFIeld input={input} meta={meta} />
                )}
              </Field>

              <Field name="contracts">
                {({ input, meta }) => (
                  <div className="col-span-12 sm:col-span-3">
                    <label
                      htmlFor={input.name}
                      className="block text-sm font-medium text-gray-700"
                    >
                      # Year of Contract
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                      <select
                        {...input}
                        className={
                          meta.error && meta.touched
                            ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-200 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                            : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md placeholder-gray-300"
                        }
                      >
                        <option value="">year</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                      </select>
                      {meta.error && meta.touched && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <ExclamationCircleIcon
                            className="h-5 w-5 text-red-500"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                    {meta.error && meta.touched && (
                      <p className="mt-2 text-sm text-red-600">{meta.error}</p>
                    )}
                  </div>
                )}
              </Field>
              <Field name="contracts_file">
                {({ input, meta }) => (
                  <UpdateGrandizerSingleFileUploadField
                    label=""
                    requied={true}
                    col={3}
                    input={input}
                    meta={meta}
                  />
                )}
              </Field>
            </FormSection>
            <FormSpy
              subscription={{ values: true }}
              component={FormPercentage}
              setRequiredField={setButtonActive}
              setButtonActive={props.savaButtonAction}
            />
          </form>
        );
      }}
    />
  );
}
