import { Fragment } from 'react';
import { Link  } from "react-router-dom";
import * as SolidIcons from '@heroicons/react/solid';
import * as OutlineIcons from '@heroicons/react/outline';
import { Disclosure, Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

// Dynamic Icons
export const HeroIcon = (props) => {
    const { icon, className = 'w-6 h-6 text-gray-600', outline = true } = props;
  
    const Icon = outline ? OutlineIcons[icon] : SolidIcons[icon];
  
    return <Icon className={className} />;
};

// Helpers
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const grandizerV1Url = process.env.REACT_APP_V1_URL
const quickLinks = [
    { name: 'Knowledge Base', href: grandizerV1Url+'/knowledge-base', bgColorClass: 'bg-indigo-500', version: 1 },
    { name: 'General Calculators', href: '/calculators', bgColorClass: 'bg-indigo-500', version: 2 },
]

export default function MobileNav(props) {
    const { user, navItems, badgeCountDashboard, badgeCountNotification, sidebarOpen, setSidebarOpen } = props
    const location = window.location.pathname

    return (
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 flex z-40">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-grandizer-gray">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-shrink-0 flex items-center px-4">
                      <a href={grandizerV1Url}><img
                        className="h-8 w-auto"
                        src={user.company_logo_url}
                        alt="Workflow"
                      /></a>
                    </div>
                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                      <nav className="px-2">
                        <div className="space-y-1">
                          {navItems ? navItems.map((item) =>
                            !item.children ? (
                              <div key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-900 text-white'
                                      : 'text-white hover:text-white hover:bg-gray-800',
                                    'group w-full flex items-center relative pl-2 py-2 text-sm font-medium rounded-md'
                                  )}
                                >
                                  <HeroIcon icon={item.icon} 
                                    className={classNames(
                                      item.current ? 'text-white' : 'text-white group-hover:text-gray-500',
                                      'mr-3 flex-shrink-0 h-6 w-6'
                                    )}
                                  />
                                  <span className="truncate">{item.name}</span>
                                  {(item.key === 'dashboard') ? (
                                    <span
                                    className="bg-red-500 text-white ml-auto mr-2 inline-block py-0.5 px-3 text-xs rounded-full">
                                    {badgeCountDashboard}
                                  </span>
                                  ) : ''}
                                </a>
                              </div>
                            ) : (
                              <Disclosure as="div" key={item.name} className="space-y-1">
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={classNames(
                                        item.current
                                          ? 'bg-gray-900 text-white'
                                          : 'text-white hover:text-white hover:bg-gray-800',
                                        'group w-full flex items-center relative pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-0'
                                      )}
                                    >
                                      <HeroIcon icon={item.icon} 
                                        className={classNames(
                                          item.current ? 'text-white' : 'text-white group-hover:text-white',
                                          'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                      />
                                      <span className="flex-1">{item.name}</span>
                                      <svg
                                        className={classNames(
                                          open ? 'text-white rotate-90' : 'text-gray-300',
                                          'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-white transition-colors ease-in-out duration-150'
                                        )}
                                        viewBox="0 0 20 20"
                                        aria-hidden="true"
                                      >
                                        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                      </svg>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="space-y-1">
                                    {item.children.map((subItem) => (
                                        (subItem.menu_type === 'v2' ? (
                                          <Link key={subItem.key} to={subItem.v2_path} reloadDocument
                                          className="group w-full flex items-center relative pl-4 pr-2 py-2 text-sm font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50">
                                            <HeroIcon icon={subItem.icon} 
                                              className={classNames(
                                                item.current ? 'text-white' : 'text-gray-300 group-hover:text-white',
                                                'mr-1 flex-shrink-0 h-4 w-4'
                                              )}
                                            />
                                            <span className="truncate relative">{subItem.name}</span>{subItem.beta ? (<span className="beta-badge beta-badge-child">BETA</span>) : ''}

                                            {(subItem.key === 'notifications') ? (
                                              <span
                                              className="bg-red-500 text-white ml-auto mr-2 inline-block py-0.5 px-3 text-xs rounded-full">
                                              {badgeCountNotification}
                                            </span>
                                            ) : ''}
                                          </Link >
                                        ) : (
                                          <Disclosure.Button
                                            key={subItem.name}
                                            as="a"
                                            href={subItem.href}
                                            className="group w-full flex items-center relative pl-4 pr-2 py-2 text-sm font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50"
                                          >
                                            <HeroIcon icon={subItem.icon} 
                                              className={classNames(
                                                item.current ? 'text-white' : 'text-gray-300 group-hover:text-white',
                                                'mr-1 flex-shrink-0 h-4 w-4'
                                              )}
                                            />
                                            <span className="truncate relative">{subItem.name}</span>{subItem.beta ? (<span className="beta-badge beta-badge-child">BETA</span>) : ''}

                                            {(subItem.key === 'notifications') ? (
                                              <span
                                              className="bg-red-500 text-white ml-auto mr-2 inline-block py-0.5 px-3 text-xs rounded-full">
                                              {badgeCountNotification}
                                            </span>
                                            ) : ''}
                                          </Disclosure.Button>
                                        ) )
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            )
                          ) : ''}
                        </div>
                        <div className="mt-8">
                          <h3
                            className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                            id="mobile-teams-headline"
                          >
                            Quick Links
                          </h3>
                          <div className="mt-1 space-y-1" role="group" aria-labelledby="mobile-teams-headline">
                            {quickLinks.map((quickLink) => (
                              <a
                                key={quickLink.name}
                                href={quickLink.href}
                                className="group flex items-center px-3 py-2 text-base leading-5 font-medium text-white rounded-md hover:text-gray-900 hover:bg-gray-50"
                              >
                                <span
                                  className={classNames(quickLink.bgColorClass, 'w-2.5 h-2.5 mr-4 rounded-full')}
                                  aria-hidden="true"
                                />
                                <span className="truncate">{quickLink.name}</span>
                              </a>
                            ))}
                          </div>
                        </div>
                      </nav>  
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
        </Transition.Root>
    );
}