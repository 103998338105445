import { useState } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function UserSelectField(props) {
    const { label, input, meta, values, people, required = false, showCompany = false } = props
    const [query, setQuery] = useState('')
    const [selectedPerson, setSelectedPerson] = useState(input.value)

    const filteredPeople =
        query === ''
        ? people
        : people.filter((person) => {
            return person.name.toLowerCase().includes(query.toLowerCase())
            })

    function handleChange(value)
    {
        setSelectedPerson(value)
        input.onChange(value)
    }

    return (
        <Combobox as="div" value={selectedPerson} onChange={handleChange}>
        <Combobox.Label className="block text-sm font-medium text-gray-700">{label} {required && <span className="text-red-500">*</span>}</Combobox.Label>
        <div className="relative mt-1">
            <Combobox.Input
                className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(person) => (person) ? person?.name : 'Unassigned'}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>

            {filteredPeople.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredPeople.map((person) => (
                <Combobox.Option
                    // disabled={showCompany ? !person.is_available_for_attendance : true} 
                    key={person.id}
                    value={person}
                    className={({ active }) =>
                    classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                    )
                    }
                >
                    {({ active, selected }) => (
                    <>
                        <div className="flex items-center">
                        <img src={person.avatar_url} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                        <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>
                            <div className={showCompany ? (!person.is_available_for_attendance ? "user-picker-disabled" : '') : ''}>{person.name}</div>
                            {!showCompany && <div className={showCompany ? (!person.is_available_for_attendance ? "user-picker-disabled font-normal text-xs user-picker-name" : 'font-normal text-xs user-picker-name') : ''}>{person.email}</div>}
                            {showCompany && <div className={showCompany ? (!person.is_available_for_attendance ? "user-picker-disabled font-normal text-xs user-picker-name" : 'font-normal text-xs user-picker-name') : ''}>
                                {person.is_available_for_attendance && <span>{person.company}</span>}
                                {!person.is_available_for_attendance && <span>Need office setup</span>}
                            </div>}
                        </span>
                        </div>

                        {selected && (
                        <span
                            className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'text-white' : 'text-indigo-600'
                            )}
                        >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                        )}
                    </>
                    )}
                </Combobox.Option>
                ))}
            </Combobox.Options>
            )}
        </div>
        </Combobox>
    )
}
